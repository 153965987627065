'use client'

import { ReactElement, FC, useRef, useEffect } from 'react'
import { useIsMobileOrTablet } from '../../utils/screen.utils'

type layoutProps = {
  children: ReactElement
  className?: string | ''
}

const Animate: FC<layoutProps> = ({ children, className }) => {
  const ref = useRef<HTMLDivElement>(null)
  const isTablet = useIsMobileOrTablet()

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          if (ref.current) {
            ref.current
              .querySelectorAll(
                '.Item-09 .fat-title, .Block-10 .fat-title, .swiper-slide, .title-h2, p, figure, .media, .wrapper-push-item, .card, .services-content article, .title-h5, .benefit ul li, .list-selection-wrapper, .push-price, .buttons',
              )
              .forEach((el) => {
                el.classList.add('slide-in')
              })
          }
        }
      },
      { rootMargin: isTablet ? '0px' : '-200px' },
    )

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => observer.disconnect()
  }, [])

  return (
    <div ref={ref} className={`animate ${className}`}>
      {children}
    </div>
  )
}

export default Animate
