'use client'

import { FunctionComponent, useContext } from 'react'
import { ContrastContext, ContrastContextType } from '../../context/ContrastContext'
import { useIsMobile } from '../../utils/screen.utils'
import Image from '../Image/Image'
import Link from '../Link/Link'
import { useTranslation } from '../../context/ConfigContext'

export const Picture: FunctionComponent<CmsMediaPicture> = ({
  url_image_mobile,
  url_image_desktop,
  content,
  button,
  link,
}) => {
  const isTablet = useIsMobile()
  const t = useTranslation()
  const { contrast } = useContext(ContrastContext) as ContrastContextType

  return (
    <section className='media'>
      <picture aria-hidden='true' className={contrast ? 'contrast' : ''}>
        {isTablet ? (
          <Image src={url_image_mobile} alt='' width={1500} height={500} />
        ) : (
          <Image src={url_image_desktop} alt='' width={6900} height={300} />
        )}
      </picture>
      <div className='media-over'>
        <h2 className='title-h2'>{t(content)}</h2>
        <Link href={t(link)} className='button border-white'>
          {t(button)}
        </Link>
      </div>
    </section>
  )
}
