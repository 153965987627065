'use client'

import { useContext, useEffect, useState } from 'react'
import Swiper from 'swiper'
import { ModalContext, ModalContextType } from '../../context/ModalContext'
import ModalContact from '../Modal/ModalContact'
import { gtmPush } from '../../utils/gtmHelpers'
import CmsBlockClient from './CmsBlock.client'
import AnimationVideoOnDetailBlogItem from '../BlogCmsComponents/Detail/AnimationVideoOnDetailBlogItem'
import AnimationInView from '../BlogCmsComponents/Detail/AnimationInView'

const CmsBlockNoHeadless = ({
  content,
  shouldRemoveParentClass,
}: {
  content?: string | null
  title?: string | null
  pageLayout?: string | null
  cmsPageKey: string
  className?: string
  shouldRemoveParentClass?: boolean
}) => {
  const { addModal } = useContext(ModalContext) as ModalContextType

  const gtmEvent = (actionType) => {
    gtmPush({
      event: 'contact_action',
      contact_action_type: `${actionType}`,
    })
  }

  useEffect(() => {
    const contactActions = document.querySelectorAll<HTMLElement>(
      '.Button.cta-button[data-contact]',
    )

    contactActions?.forEach((element) => {
      element.addEventListener('click', () => {
        gtmEvent(element.dataset.contact)
      })
    })
    // For CMS Page, we have some sliders for mobile
    const firstSelector = '.cms-block-noheadless .Block-08:first-child .swiper-container'
    const secondSelector = '.cms-block-noheadless .Block-08:last-child .swiper-container'
    const listSelector = '.cms-block-noheadless .List-05 .swiper-container'

    const firstSwiper = document.querySelector(firstSelector)
    const secondSwiper = document.querySelector(secondSelector)
    const listSwiper = document.querySelector(listSelector)

    if (firstSwiper && secondSwiper) {
      new Swiper(firstSelector, {
        spaceBetween: 8,
        slidesPerView: 1,
        breakpoints: {
          1024: {
            enabled: false,
          },
        },
      })
      new Swiper(secondSelector, {
        spaceBetween: 8,
        slidesPerView: 1,
        breakpoints: {
          1024: {
            enabled: false,
          },
        },
      })
    } else if (listSwiper) {
      new Swiper(listSelector, {
        spaceBetween: 8,
        slidesPerView: 1,
        breakpoints: {
          1024: {
            enabled: false,
          },
        },
      })
    }
  }, [gtmEvent])

  function cmsContactModal() {
    const [isModalOpen, setIsModalOpen] = useState(false)

    useEffect(() => {
      const selectorLinkOpenModal = '.js-trigger-modal-contact'
      const linkOpenModalEl = document.querySelector(selectorLinkOpenModal)

      const openContactModal = () => {
        const uuid = crypto.randomUUID()
        setIsModalOpen(true)
        addModal({
          uuid,
          component: <ModalContact uuid={uuid} onClose={() => setIsModalOpen(false)} />,
        })
      }

      if (linkOpenModalEl) {
        linkOpenModalEl.addEventListener('click', openContactModal)
      }
    }, [isModalOpen])
  }

  cmsContactModal()

  const targetClass = 'cms-block'

  return (
    <div className='page-main'>
      <div className='columns'>
        <div className='column main'>
          <div
            className={`${targetClass} ${shouldRemoveParentClass ? 'parent-to-remove' : ''}`}
            dangerouslySetInnerHTML={{ __html: content || '{}' }}
          />
        </div>

        {/* FOR VIDEO CONTROL & SLIDER */}
        <CmsBlockClient className={targetClass} shouldRemoveParentClass={shouldRemoveParentClass} />

        {/* FOR THE VIDEO AUTOPLAY */}
        <AnimationVideoOnDetailBlogItem />

        {/* ANIMATION ON VIEW */}
        <AnimationInView />
      </div>
    </div>
  )
}

export default CmsBlockNoHeadless
