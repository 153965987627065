'use client'

import { FunctionComponent, ReactNode } from 'react'
import { motion } from 'framer-motion'

type MotionProps = {
  children: ReactNode
}
const Motion: FunctionComponent<MotionProps> = ({ children }) => (
    <motion.div
      style={{ y: 150, opacity: 0 }}
      animate={{ y: 0, opacity: 100 }}
      transition={{ duration: 0.5 }}>
      {children}
    </motion.div>
  )

export default Motion
