'use client'

import { useRef } from 'react'
import { CustomCmsSlider } from '../../../domain/cmsSlider.types'
import { useTranslation } from '../../context/ConfigContext'
import HeaderSlider from './HeaderSlider'

type HeaderHomepageProps = {
  slider: CustomCmsSlider | null
  contrast?: boolean
  page?: {
    page: string
  }
}

const HeaderHomepage = ({ slider, contrast, page }: HeaderHomepageProps) => {
  const explorerRef = useRef<HTMLDivElement>(null)
  const t = useTranslation()

  const onClickExplorer = () => {
    explorerRef.current?.scrollIntoView({
      behavior: 'smooth',
    })
  }

  return (
    <section className='header-homepage'>
      <HeaderSlider slider={slider} contrast={contrast} page={page} />
      <div
        className='header-homepage-explorer'
        role='button'
        ref={explorerRef}
        onClick={onClickExplorer}
        onKeyDown={onClickExplorer}
        tabIndex={0}>
        <div className='underline'>{t('home-slider-explorer-text', {}, true)}</div>
        <div className='explorer-animation'>
          <div className='explorer-animation-bullet' />
          <div className='explorer-animation-bullet' />
          <div className='explorer-animation-bullet' />
        </div>
      </div>
    </section>
  )
}

export default HeaderHomepage
