'use client'

import { FunctionComponent, useEffect } from 'react'
import { gtmCategory, gtmPush } from '../../../utils/gtmHelpers'

type InitCmsBlocksJsonSliderProps = {
  data?: parsedResultType[]
}
const CmsBlocksJsonGtmEvent: FunctionComponent<InitCmsBlocksJsonSliderProps> = ({ data }) => {
  useEffect(() => {
    // GTM
    if (data !== null && data?.length !== 0) {
      const productItems: any[] = []

      data?.map((item, index) => {
        let obj = {}
        const gtmCategoryObj = gtmCategory(item.gtm_categories as string)

        obj['item_name'] = item?.gtm_name
        obj['item_id'] = item?.sku
        obj['price'] = item?.price
        obj['item_brand'] = 'Messika'
        obj['index'] = index += 1
        obj['item_list_id'] = 'selection_accueil'
        obj['item_list_name'] = 'Selection Accueil'
        obj = { ...obj, ...gtmCategoryObj }

        productItems.push(obj)
      })

      gtmPush({
        event: 'view_item_list',
        ecommerce: {
          item_list_id: 'selection_accueil',
          item_list_name: 'Selection Accueil',
          items: productItems,
        },
      })
    }
  }, [])

  return null
}

export default CmsBlocksJsonGtmEvent
