'use client'

import { FunctionComponent, useContext, useEffect, useRef, useState } from 'react'

import { ContrastContext } from '../../context/ContrastContext'
import { useIsMobile } from '../../utils/screen.utils'
import Icon from '../Icon/Icon'
import { Icons } from '../Icon/constants'
import Link from '../Link/Link'
import { useTranslation } from '../../context/ConfigContext'

export const Video: FunctionComponent<CmsMediaVideo> = ({
  url_mobile,
  url_image_mobile,
  url_desktop,
  url_image_desktop,
  content,
  button,
  link,
}) => {
  const isTablet = useIsMobile()
  const { contrast } = useContext(ContrastContext)
  const t = useTranslation()

  const videoRef = useRef<HTMLVideoElement>(null)
  const [isPlayed, play] = useState<boolean>(true)
  const [isMuted, mute] = useState<boolean>(true)

  const handlePlayPause = () => {
    if (videoRef.current?.paused) {
      play(true)
      return videoRef.current?.play()
    }

    play(false)
    videoRef.current?.pause()
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      function (entries, observer) {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            return
          }
          const sourceEl = entry.target.querySelector<HTMLElement>('source')
          if (!sourceEl || !sourceEl.dataset.src) {
            return
          }
          sourceEl.setAttribute('src', sourceEl.dataset.src)
          ;(entry.target as HTMLVideoElement).load()
          observer.unobserve(entry.target)
        })
      },
      {
        rootMargin: '300px',
      },
    )

    if (videoRef.current) {
      observer.observe(videoRef.current)
    }

    return () => observer.disconnect()
  }, [])

  return (
    <section className='media'>
      <div className={contrast ? 'contrast' : ''}>
        <video
          ref={videoRef}
          loop
          autoPlay
          playsInline
          muted={isMuted}
          aria-hidden='true'
          poster={isTablet ? url_image_mobile : url_image_desktop}>
          {isTablet ? (
            <source data-src={url_mobile} type='video/mp4' />
          ) : (
            <source data-src={url_desktop} type='video/mp4' />
          )}
        </video>
      </div>
      <div className='video-control'>
        <button type='button' aria-label='play' onClick={() => handlePlayPause()}>
          {isPlayed ? <Icon iconName={Icons.PAUSE} /> : <Icon iconName={Icons.PLAY} />}
        </button>
        <button aria-label='mute' onClick={() => mute(!isMuted)}>
          {isMuted ? <Icon iconName={Icons.SOUND_OFF} /> : <Icon iconName={Icons.SOUND_HIGH} />}
        </button>
      </div>
      <div className='media-over'>
        <h2 className='title-h2'>{t(content)}</h2>
        <Link href={link} className='button border-white'>
          {t(button)}
        </Link>
      </div>
    </section>
  )
}
