'use client'

import { getCookie, setCookie } from 'cookies-next'
import { FunctionComponent, useContext, useEffect } from 'react'
import { ModalContext, ModalContextType } from '../../context/ModalContext'
import ModalNewsletter from '../Modal/ModalNewsletter'

const CookieNewsletter: FunctionComponent = () => {
  const { addModal, removeModal } = useContext(ModalContext) as ModalContextType

  useEffect(() => {
    if (getCookie('newsletter') !== true) {
      const uuid = crypto.randomUUID()
      const handleClose = () => {
        setCookie('newsletter', true)
        removeModal(uuid)
      }
      setTimeout(() => {
        addModal({
          uuid,
          component: <ModalNewsletter uuid={uuid} onClose={handleClose} />,
        })
      }, 3000)
    }
  }, [])

  return <></>
}

export default CookieNewsletter
